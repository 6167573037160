import React from 'react'
import SEO from '../components/seo'
import Container from '../components/layout'
import LayoutContainer from '../containers/layout'
import { Typography } from '@material-ui/core'

const NotFoundPage = () => (
  <LayoutContainer>
    <SEO title="404: Not found" />
    <Container>
      <Typography variant="h1">Not found</Typography>
      <Typography>The page you attempted to access does not exist yet.</Typography>
    </Container>
  </LayoutContainer>
)

export default NotFoundPage
